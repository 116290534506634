/* App.css */

body {
  font-family: 'Arial', sans-serif;
  background-color: #F3F2F2;
  color: #121C37;
  margin: 0;
  padding: 0;
}

header {
  background-color: #121C37;
  color: #F3F2F2;
  padding: 1rem;
  text-align: center;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 1rem 0;
}

th,
td {
  padding: 0.5rem;
  text-align: left;
  border-bottom: 1px solid #F44336;
}

th {
  background-color: #5BC49A;
  color: #F3F2F2;
}

tr:nth-child(even) {
  background-color: #5EBBC5;
}

tr:nth-child(odd) {
  background-color: #F3F2F2;
}

tr:hover {
  background-color: #DF6118;
  color: #F3F2F2;
}

.container {
  max-width: 1200px;
  margin: auto;
  padding: 0 1rem;
}

h1 {
  color: #DF6118;
}

.main-header {
  background-color: #121C37;
  color: #DF6118;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar {
  display: flex;
  align-items: center;
}

.navbar-brand {
  font-weight: bold;
  font-size: 1.5rem;
}

.navbar-nav {
  display: flex;
}

.nav-item {
  color: #F3F2F2;
  text-decoration: none;
  margin-left: 2rem;
  font-size: 1rem;
  padding: 0.5rem 1rem;
}

.nav-item:hover {
  background-color: #5EBBC5;
  border-radius: 5px;
}

.navbar-brand img {
  max-width: 60%;
  object-fit: cover;
}

.header-icon {
  background: url('//maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
  color: #DF6118;
}

.section-heading {
  font-size: 2.5rem;
  font-weight: 600;
  color: #121C37;
  padding: 1rem 0;
  margin-left: 2rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: flex;
  /* Align items in a row */
  align-items: center;
  /* Center items vertically */
}

.header-icon {
  margin-right: 10px;
  /* Space between the icon and the text */
  font-size: 1.5rem;
  /* Adjust size as needed */
}

.calendar-icon {
  margin-right: 10px;
  /* Space between the icon and the text */
  font-size: 2.5rem;
  /* Adjust the size as needed */
  /* Add any additional styles you want for the calendar icon here */
}

.star-icon {
  margin-right: 10px;
  /* Space between the icon and the text */
  font-size: 2.5rem;
  /* Adjust the size as needed */
  /* Add any additional styles you want for the calendar icon here */
}


/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .section-heading {
    font-size: 2rem;
    /* Slightly smaller font size on mobile */
    margin-left: 1rem;
    /* Adjust alignment for smaller screens */
  }
}

/* Styling for the container of the section heading */
.section-heading-container {
  text-align: center;
  /* Center the container of the heading */
  margin-bottom: 1rem;
  /* Space after the heading */
}

/* Begin Cards */
.date-heading {
  font-size: 1.75rem;
  color: #121C37;
  margin-left: 2rem;
  margin-bottom: 1rem;
  /* Add more styles if needed */
}

.games-container {
  width: 100%;
  /* Take up the full width */
  padding: 20px;
  /* Adjust the padding as needed */
}


.cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  /* Adjust the space between cards */
  justify-content: flex-start;
  /* Align cards to the start of the container */
}

/* Ensure your card styles make them take up the full width on small screens */
@media (max-width: 768px) {
  .cards-container {
    justify-content: center;
  }

  .card {
    max-width: 100%;
    /* Cards should not exceed the width of the container */
  }
}

.card {
  background: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
  /* Fixed width for each card */
  margin-bottom: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.card-header {
  background-color: #121C37;
  /* Oxford Blue */
  color: #F3F2F2;
  /* White Smoke */
  padding: 15px;
  font-size: 1.1rem;
  text-align: center;
  border-bottom: 4px solid #DF6118;
  /* Verdigrid */
  min-height: 48px;
  /* Adjust this value as needed to fit your content */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* This will center the content vertically */
}


.card-body {
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.date {
  font-size: 0.9rem;
  color: #777;
  margin-top: -10px;
  text-align: center;
  padding-bottom: 10px;
}

.odds-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.odds-entry {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  border-top: 1px solid #eee;
  /* Light line for each odds entry */
}

.odds-entry:first-child {
  border-top: none;
}

.odds-entry span {
  flex: 1;
  text-align: center;
}

.odds-label {
  font-weight: bold;
}

.odds-value {
  /* additional styling if needed */
}

.odds-better {
  color: #F44336;
  /* Green for better odds */
}

.odds-worse {
  color: #4CAF50;
  /* Red for worse odds */
}

.predictions {
  background-color: #F3F2F2;
  /* White Smoke */
  border-radius: 8px;
  padding: 10px;
  text-align: center;
  font-size: 0.9rem;
}

.predictions span {
  display: block;
  /* Each prediction on its own line */
  margin: 5px 0;
}

/* Each prediction on its own line */

.shared-table-style {
  width: auto; /* Adjust this to auto or a specific percentage */
  max-width: 80%; /* Maximum width of the table */
  margin: 1rem auto; /* Center the table */
  border-collapse: collapse;
}

.shared-table-style thead th {
  background-color: #121C37; /* Oxford Blue */
  color: #FFFFFF;
  padding: 10px 15px;
  text-align: left;
}

.shared-table-style tbody tr {
  border-bottom: 1px solid #e1e1e1;
}

.shared-table-style tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.shared-table-style tbody tr:hover {
  background-color: #f0f0f0;
}

.shared-table-style td {
  padding: 10px 15px;
  text-align: left;
}

.progress-bar-container {
  position: relative; /* For positioning the text within the bar */
  background-color: #e1e1e1;
  border-radius: 5px;
  padding: 0; /* Reset padding if necessary */
}

.progress-bar {
  height: 20px; /* Increased height for visibility */
  border-radius: 5px;
  background-color: #5BC49A; /* Verdigrid */
  width: 0; /* Initial width, will be set dynamically */
}

@media (max-width: 768px) {
  /* Responsive table styles */
  .shared-table-style {
      /* Adjustments for smaller screens */
  }
}

.shared-table-style tbody tr:hover {
  background-color: rgb(110, 236, 202); /* Ensure this color contrasts with the text color */
  color: #000; /* Set a text color for hover state if needed */
}

/* Position the text over the progress bar */
.progress-bar-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  line-height: 20px; /* Match the height of the progress bar */
  color: #121C37; /* Text color that contrasts with the bar */
}